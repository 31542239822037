@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




@media screen and (max-width: 600px) {
  .container {
    padding-bottom: 0;
    /* Adjust as needed */
  }
}


/* .wrapper {
  display: inline-grid;

  grid-template-columns: repeat(auto-fit, minmax(350px, 3fr));
  padding: 0px;
  overflow-x: hidden;
  margin: 0px;

} */

.box>img {
  width: 100%;
}

.box {
  padding: 5px;
  margin: 10px;
  text-align: center;
}

.foot {
  position: fixed;
}